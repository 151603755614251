/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';
import Loading from '../../components/Loading';
import Items from './Items';
import Send from './Send';
import useStyles from './styles';

function Order() {
  const classes = useStyles();
  const [send, setSend] = useState(false);
  const { loading, order } = useSelector(state => state.order);
  const { user } = useSelector(state => state.auth);

  return (
    <>
      <div className={classes.root}>
        {order && <Items />}
        <div className={classes.footer}>
          <Link to="/categories">
            <ArrowBackIos fontSize="large" />
          </Link>

          <h1 onClick={() => setSend(true)}>enviar pedido</h1>
        </div>
      </div>

      <Send send={send} setSend={setSend} user={user} />

      <Loading loading={loading} />
    </>
  );
}

export default Order;
