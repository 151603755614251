import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@material-ui/core';
import { orderRequest } from '../../../store/modules/order/actions';
import useStyles from './styles';

function Send({ send, setSend, user }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [table, setTable] = useState(0);
  const [text, setText] = useState('');

  const { order } = useSelector(state => state.order);

  useEffect(() => {
    if (send) {
      setTable(0);
      setText('');
    }
  }, [send]);

  const handleKeyPress = val => {
    const value = `${text}`;
    if (value.trim().length === 0) {
      if (val === '0') return false;
      setText(val);
    } else {
      setText(prev => [`${prev}${val}`]);
    }
    return false;
  };

  const hanldeCancel = () => {
    const value = `${text}`;

    if (value.trim().length === 0 && table >= 1) {
      setText(String(table));
      setTable(0);
      return;
    }

    if (value.trim().length === 0) {
      setSend(false);
      return;
    }

    setText('');
  };

  const hanldeEnter = async () => {
    const value = Number(text);

    if (value === 0) return;

    if (user.comanda === 'S' && table === 0) {
      setTable(value);
      setText('');
      return;
    }

    if (table === 0) setTable(value);

    await setSend(false);

    const { vendedor_id } = user;

    const pedido = {
      vendedor_id,
      mesa: user.comanda === 'S' ? table : 0,
      comanda: value,
      items: order.items.map(item => {
        const { produto_id, amount: quantidade, comment = null } = item;
        return {
          produto_id,
          quantidade,
          comment_id: comment ? comment.comment_id : 0,
        };
      }),
    };

    dispatch(orderRequest(pedido));
  };

  return (
    <Drawer anchor="bottom" open={send} onClose={() => setSend(false)}>
      <div className={classes.root}>
        <div className={classes.comanda}>
          {user.comanda === 'S' && table === 0 ? (
            <span>mesa</span>
          ) : (
            <span>comanda</span>
          )}

          <span className={classes.number}>
            {`${text}`.trim().length >= 1 ? `${text}` : '?'}
          </span>
        </div>
        <div className={classes.keyboard}>
          <div className={classes.row}>
            <a
              href
              className={classes.number}
              onClick={() => handleKeyPress('1')}
            >
              <span>1</span>
            </a>

            <a
              href
              className={classes.number}
              onClick={() => handleKeyPress('2')}
            >
              <span>2</span>
            </a>

            <a
              href
              className={classes.number}
              onClick={() => handleKeyPress('3')}
            >
              <span>3</span>
            </a>
          </div>
          <div className={classes.row}>
            <a
              href
              className={classes.number}
              onClick={() => handleKeyPress('4')}
            >
              <span>4</span>
            </a>
            <a
              href
              className={classes.number}
              onClick={() => handleKeyPress('5')}
            >
              <span>5</span>
            </a>

            <a
              href
              className={classes.number}
              onClick={() => handleKeyPress('6')}
            >
              <span>6</span>
            </a>
          </div>
          <div className={classes.row}>
            <a
              href
              className={classes.number}
              onClick={() => handleKeyPress('7')}
            >
              <span>7</span>
            </a>

            <a
              href
              className={classes.number}
              onClick={() => handleKeyPress('8')}
            >
              <span>8</span>
            </a>

            <a
              href
              className={classes.number}
              onClick={() => handleKeyPress('9')}
            >
              <span>9</span>
            </a>
          </div>
          <div className={classes.row}>
            <a href className={classes.cancel} onClick={hanldeCancel}>
              <span>cancel</span>
            </a>

            <a
              href
              className={classes.number}
              onClick={() => handleKeyPress('0')}
            >
              <span>0</span>
            </a>

            <a href className={classes.enter} onClick={hanldeEnter}>
              <span>enter</span>
            </a>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default Send;
